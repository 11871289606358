import {
  AspectRatio,
  Button,
  Center,
  Container,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import copy from "copy-to-clipboard";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/configs";

function Auth() {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const { t } = useTranslation();

  //const [text, setText] = useState("");
  const [input, setInput] = useInputState("");

  useEffect(() => {
    setInput(code);
  }, [code, setInput]);

  const copyToClipboard = () => {
    let isCopy = copy(input);

    if (isCopy) {
      //toast.success("Copied to Clipboard");

      notifications.show({
        title: <p>{t("header.copied")}</p>,
      });
    } else {
    }
  };

  const demoProps = {
    h: 50,
    mt: "md",
  };

  return (
    <>
      <Container>
        <Button.Group>
          <Button variant="default" onClick={() => i18n.changeLanguage("en")}>
            English
          </Button>
          <Button variant="default" onClick={() => i18n.changeLanguage("ja")}>
            日本語
          </Button>
        </Button.Group>

        <Center>
          <Text> {t("header.accesstoken")}</Text>
        </Center>
        <Textarea onChange={setInput} value={input} />
      </Container>

      <Center h={100}>
        <Button onClick={copyToClipboard}>{t("header.copy")}</Button>
      </Center>
    </>
  );
}

export default Auth;
